import { useMatch } from 'react-router';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Alert, ThemeProvider } from '@mui/material';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import theme from './theme';
import AuthProvider from './components/AuthProvider';
import { NavProvider } from './components/NavProvider';
import HomePage from './components/HomePage/HomePage';
import DomainPage from './components/DomainPage/DomainPage';
import SupplierPage from './components/SupplierPage/SupplierPage';
import UserList from './components/admin/UserList/UserList';
import Upload from './components/admin/Upload/Upload';
import SummitManager from './components/admin/SumitManager/SummitManager';
import Enrol from './components/public/Enrol/Enrol';
import ToolsRoles from './components/admin/ToolsRoles/ToolsRoles';
import PowerBi from './components/admin/PowerBIManager/PowerBi';
import SpendPage from './components/SpendPage/SpendPage';
import SpendReportManager from './components/admin/SpendReportManager/SpendReportManager';
import ProductCatalog from './components/admin/ProductCatalogManager/ProductCatalog';
import { HealthCheckConfig } from '@webscopeio/react-health-check';
import Sustainability from './components/Sustainability/Sustainability';
import TabsWrappedLabel from './components/admin/SustainabilityChatbotManager/Admin';
import { AppStateProvider } from './components/SustainabilityChatbot/State/AppProvider';
import MbiNewsChatBot from './components/Layout/MbiNewsChatBot/MbiNewsChatBot';
import MbnChatbotManager from './components/admin/MbnChatbotManager/MbnChatbotManager';
const AppRoutes = () => {
  const isPublic = useMatch('/public/*');
  const supplierId = useMatch('/suppliers/:id/*')?.params.id;

  return isPublic ? (
    <Routes>
      <Route path="/public">
        <Route path="summit-booking/enrol/:token" element={<Enrol />} />
      </Route>
    </Routes>
  ) : (
    <AuthProvider>
      <NavProvider>
      {/* <HealthCheckConfig
      value={{
        services: [
          {
            name: 'backend',
            url: 'https://localhost:3001/api',
          },
        ],
        onSuccess: ({ service, timestamp }) => {
          <>{"successss"}
          <Alert severity="success"><>
          Service <strong>"{service.name}"</strong> is available since: <br />{' '}
          {timestamp.toString()}
           🎉
        </></Alert></>
        },
        onError: ({ service, timestamp }) => {
          <>{"error"}
          <Alert severity="success"> <>
          Service <strong>"{service.name}"</strong> is not available since: <br />{' '}
          {timestamp.toString()} 
          😔
        </>,</Alert></>
        },
        refreshInterval: 2000,
      }}> */}
        <Routes>
          <Route path="/domains">
            <Route path=":tab/*" element={<DomainPage />} />
            <Route path="" element={<Navigate to="/domains/feed" />} />
          </Route>
          <Route path="/suppliers/:id/*" element={<SupplierPage />} />
          <Route
            path="/suppliers/:id/spend"
            element={
              <Navigate to={`/supplier-spend-financials/${supplierId}`} />
            }
          />
          <Route path="/supplier-spend-financials" element={<SpendPage />} />
          {/* <Route path="/sustainability" element={<Sustainability />} /> */}
          <Route
            path="/supplier-spend-financials/:id"
            element={<SpendPage />}
          />
          <Route path="/admin">
            <Route path="users" element={<UserList />} />
            <Route path="toolsRoles">
              <Route path=":tab/*" element={<ToolsRoles />} />
              <Route path="" element={<Navigate to="powerBi" />} />
            </Route>
            <Route path="powerBi" element={<PowerBi />} />
            <Route path="spend-financials" element={<SpendReportManager />} />
            <Route path="upload">
              <Route path=":tab/*" element={<Upload />} />
              <Route path="" element={<Navigate to="suppliers" />} />
            </Route>
            <Route path="summit">
              <Route path=":tab/*" element={<SummitManager />} />
              <Route path="" element={<Navigate to="sessions" />} />
            </Route>
            <Route path="sustainabilityChatbot">
              <Route path=":tab/*" element={<TabsWrappedLabel />} />
              <Route path="" element={<Navigate to="sustainabilityChatbot" />} />
            </Route>
            <Route path="productCatalog">
              <Route path=":tab/*" element={<ProductCatalog />} />
              <Route path="" element={<Navigate to="productCatalog" />} />
            </Route>
            
            <Route path="mbn-chatbot"  element={<MbnChatbotManager />} />
            
          </Route>

          <Route path="/">
            <Route path=":tab/*" element={<HomePage />} />
            <Route path="" element={<Navigate to="/summit-booking" />} />
          </Route>
        </Routes>
        {/* </HealthCheckConfig> */}
      </NavProvider>
     
    </AuthProvider>
  );
};

function App() {
  const { enableLinkTracking } = useMatomo();

  enableLinkTracking();

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
      <AppStateProvider>

        <AppRoutes />
        </AppStateProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
