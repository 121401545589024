import React, { useEffect, useState } from 'react';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Collapse from '@mui/material/Collapse';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AttachmentIcon from '@mui/icons-material/Attachment';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import ShareIcon from '@mui/icons-material/Share';
import newsClient from '../../../services/newsClient';
import PostSkeleton from '../PostSkeleton/PostSkeleton';
import { Strategy } from '../types';
import { POST_TYPES } from '../data';
import TabPanel from '../../ui/mui/TabPanel/TabPanel';
import ContentBox from '../shared/ContentBox/ContentBox';
import DownloadPPT from '../StrategyPPTDownload/DownloadPPT';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

interface CollapseBoxProps {
  title: string;
  summary: JSX.Element | JSX.Element[];
  children: JSX.Element | JSX.Element[];
}

const CollapseBox = ({ title, summary, children }: CollapseBoxProps) => {
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
    // TODO: scroll to content
  };

  return (
    <Box>
      <Divider sx={{ my: 2 }} />
      <Typography component="h4" variant="h6">
        {title}
      </Typography>
      <Box>{summary}</Box>
      <Collapse in={open}>{children}</Collapse>
      <Stack direction="row" justifyContent="center" alignItems="center">
        <Button
          size="large"
          startIcon={
            open ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />
          }
          onClick={handleToggle}
        >
          Read {open ? 'less' : 'more'}
        </Button>
      </Stack>
    </Box>
  );
};

interface StrategyShowProps {
  id: number | null;
  onClose: () => void;
}

export default function StrategyShow({ id, onClose }: StrategyShowProps) {
  const [post, setPost] = useState<Strategy>();
  const [tab, setTab] = useState<string>('market');
  const [likes, setLikes] = useState<number>(0);
  const [isLiked, setIsLiked] = useState<boolean>(false);
  const { trackPageView } = useMatomo();
  const { pushInstruction } = useMatomo();
  const useStyles = makeStyles(() => ({
    backDrop: {
      backdropFilter: 'blur(3px)',
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    newsClient
      .post('posts', {
        post_type: [POST_TYPES.STRATEGIES],
        id: [id],
      })
      .then((response) => {
        setPost(response.data);
        setLikes(response.data.likes);
        setIsLiked(response.data.is_liked);
        const postUrl = `${process.env.REACT_APP_MOBILE_API}/deeplink/strategies2/${id}`;
        trackPageView({
          documentTitle: 'Strategies/Strategy_Detail',
        });
        pushInstruction(
          'trackContentImpression',
          `Strategy_${response.data.title.substring(0, 20)}`,
          postUrl,
        );
      })
      .catch((error) => {
        // TODO: handle errors
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleLikeClick = () => {
    setIsLiked(!isLiked);
    setLikes(isLiked ? likes - 1 : likes + 1);
    newsClient.post(`post-like/${id}`);
  };

  return (
    <Dialog
      open={true}
      maxWidth="lg"
      fullWidth
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
    >
      <DialogTitle>
        <Typography>&#8205;</Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <IconButton
          aria-label="close"
          disabled={!post}
          href={`mailto:?subject=${post?.title}&body=Hey, I´d like to share this link with you %0A${process.env.REACT_APP_MOBILE_API}/deeplink/strategies2/${id}`}
          sx={{
            position: 'absolute',
            right: 50,
            top: 8,
            color: (theme) => theme.palette.error.light,
          }}
        >
          <ShareIcon />
        </IconButton>
      </DialogTitle>
      {post ? (
        <DialogContent sx={{ p: 0 }}>
          <Divider />
          <Divider sx={{ mb: 1 }} />
          <Box p={3}>
            <Stack justifyContent="center" alignItems="center">
              <img alt={post.title} height={180} src={post.thumbnail} />
            </Stack>
            <Typography
              variant="h5"
              component="h1"
              fontWeight="bold"
              sx={{ mt: 1 }}
            >
              {post.title}
            </Typography>
            <Typography variant="caption" sx={{ color: 'gray' }}>
              {new Intl.DateTimeFormat('en-GB', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              }).format(new Date(post.date))}
              By {post.author.name}
            </Typography>
            <Box sx={{ cursor: 'pointer' }} onClick={handleLikeClick}>
              <Stack direction="row" alignItems="center" gap={1}>
                {isLiked ? (
                  <ThumbUpIcon color="primary" />
                ) : (
                  <ThumbUpOffAltIcon color="primary" />
                )}
                {likes}
              </Stack>
            </Box>
            <Typography
              sx={{
                fontStyle: 'italic',
                color: (theme) => theme.palette.grey[500],
                pb: 1,
              }}
            >
              {post.excerpt}
            </Typography>
            <CollapseBox
              title="Market & Trends"
              summary={<ContentBox content={post.global_market.summary} />}
            >
              <ContentBox content={post.global_market.detail} />
            </CollapseBox>
            {post.regional_market.summary && (
              <CollapseBox
                title="Regional Trends"
                summary={
                  <ContentBox content={post.regional_market.summary} />
                }
              >
                <ContentBox content={post.regional_market.detail} />
              </CollapseBox>
            )}
            <CollapseBox
              title="Spend"
              summary={<ContentBox content={post.spend.summary} />}
            >
              <ContentBox content={post.spend.detail} />
            </CollapseBox>
            <CollapseBox
              title="Opportunities & Risks"
              summary={<ContentBox content={post.opportunities.summary} />}
            >
              <ContentBox content={post.opportunities.detail} />
            </CollapseBox>
            <CollapseBox
              title="Sourcing strategy - objectives"
              summary={
                <ul>
                  {post.objectives.map((objective) =>
                    objective.cards.map((card) => (
                      <li key={card.id}>
                        <Typography>{card.name}</Typography>
                      </li>
                    )),
                  )}
                </ul>
              }
            >
              {post.objectives.map((objective, index) => (
                <Box
                  key={index}
                  sx={{
                    p: 1,
                    my: 2,
                    backgroundColor: '#ecedee',
                    borderRadius: 1,
                  }}
                >
                  {objective.cards.map((card) => (
                    <Chip
                      key={card.id}
                      label={card.name}
                      color="primary"
                      sx={{ mx: 0.5 }}
                    />
                  ))}
                  <ContentBox content={objective.detail} />
                </Box>
              ))}
            </CollapseBox>
            <CollapseBox
              title="Sourcing strategy - methods"
              summary={
                <ul>
                  {post.methods.map((method) =>
                    method.cards.map((card) => (
                      <li key={card.id}>
                        <Typography>{card.name}</Typography>
                      </li>
                    )),
                  )}
                </ul>
              }
            >
              {post.methods.map((method, index) => (
                <Box
                  key={index}
                  sx={{
                    p: 1,
                    my: 2,
                    backgroundColor: '#ecedee',
                    borderRadius: 1,
                  }}
                >
                  {method.cards.map((card) => (
                    <Chip
                      key={card.id}
                      label={card.name}
                      color="primary"
                      sx={{ mx: 0.5 }}
                    />
                  ))}
                  <ContentBox content={method.detail} />
                </Box>
              ))}
            </CollapseBox>
            {post.attachments.length > 0 && (
              <Stack direction="row" spacing={1} pb={1}>
                <AttachmentIcon color="primary" />
                <Typography color="primary">Attachments</Typography>
              </Stack>
            )}
            {post.attachments.map((attachment, i) => (
              <Button
                key={i}
                variant="contained"
                color="inherit"
                sx={{ m: 1 }}
                startIcon={<AttachFileIcon />}
                href={attachment.url}
              >
                {attachment.name}
              </Button>
            ))}
            <Divider sx={{ my: 2 }} />
            <Box sx={{ cursor: 'pointer', mt: 1 }} onClick={handleLikeClick}>
              <Stack direction="row" alignItems="center" gap={1}>
                {isLiked ? (
                  <ThumbUpIcon color="primary" />
                ) : (
                  <ThumbUpOffAltIcon color="primary" />
                )}
                {likes}
              </Stack>
            </Box>
            <Stack direction="row" alignItems="center" spacing={3} paddingY={2}>
              <Avatar
                alt={post.author.name}
                src={`${process.env.REACT_APP_API_ENTRYPOINT}/api/contacts/avatars/${post.author.email}`}
                sx={{ width: 87, height: 87 }}
              />
              <Typography>
                {post.author.name}
                <br />
                {post.author.email}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1} pb={1}>
              <TurnedInIcon color="primary" />
              <Typography color="primary">Article tags</Typography>
            </Stack>
            <Box px={1}>
              {[
                ...post.domains,
                ...post.categories,
                ...post.products,
                ...post.groups,
                ...post.topics,
                ...post.vendors,
              ].map((tag) => (
                <Chip key={tag.id} label={tag.name} sx={{ m: 0.3 }} />
              ))}
            </Box>
            <DownloadPPT
              strategy={post}
              contact={`${process.env.REACT_APP_API_ENTRYPOINT}/api/contacts/avatars/${post.author.email}` || null}
            ></DownloadPPT>
          </Box>
        </DialogContent>
      ) : (
        <PostSkeleton />
      )}
    </Dialog>
  );
}
