import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Button, ButtonGroup, Divider, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import AppsIcon from '@mui/icons-material/Apps';
import ListIcon from '@mui/icons-material/List';
import apiClient from '../../services/apiClient';

import ToolsGrid from './ToolsGrid';
import ToolsList from './ToolsList';
import PowerBiReports from '../DomainPage/PowerBiReports/PowerBiReports';
import Loader from '../ui/mui/Loader/Loader';
import { useAuth } from '../AuthProvider';
import { ROLE_HIERARCHY } from '../../data';
import { ErrorAlert } from '../ui/mui/ErrorAlert/ErrorAlert';

interface Report {
  id: string;
  name: string;
  powerBIId: string;
  isPaginated: boolean;
  iconId: number;
}

export default function ToolsPage() {
  const [displayReports, setDisplayReports] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const [productCatalogs, setAllProductCatalogs] = useState<Report[]>([]);
  const [isList, setIsList] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState(true);
  const [allReports, setAllReports] = useState<Report[]>([]);
  const [otherReport, setOtherReport] = useState<Report[]>([]);
  const [catalogReport, setCatalogReport] = useState<Report[]>([]);
  const [dependencyReport, setDependencyReport] = useState<Report[]>([]);
  const [reports, setReports] = useState<Report[]>([]);
  const [isTracked, setIsTracked] = useState<boolean>(false);



  const navigate = useNavigate();
  const { trackPageView } = useMatomo();
  const { profile } = useAuth();

  function hasRole(role: string): boolean {
    return Boolean(
      profile &&
      !profile.isCustomer &&
      ((profile?.roles || []).includes(role) ||
        Object.keys(ROLE_HIERARCHY)
          .filter((key) => (profile?.roles || []).includes(key))
          .some((key) => ROLE_HIERARCHY[key].includes(role))),
    );
  }

  useEffect(() => {
    if (!isTracked) {
      trackPageView({
        documentTitle: 'Tools/Tools_Page',
      });
      setIsTracked(true);
    }
  }, [isTracked]);

  useEffect(() => {
    setDisplayReports(false);
    setIsList(true);
    setIsLoading(true);
    apiClient
      .get('/productCatalog', { params: { toDisplay: true } })
      .then((res: any) => {
        setIsLoading(false);
        setAllProductCatalogs(
          res.data.records.filter((pc: any) => pc.status === 1),
        );
      });
    apiClient
      .get('/reports', {
        // :todo: add domain filter
      })
      .then((res: any) => {
        setReports(res.data.records);
        const catalog = res.data.records.find((cr: any) => cr.name.toUpperCase() === "OPTICS CATALOG") ? [res.data.records.find((cr: any) => cr.name.toUpperCase() === "OPTICS CATALOG")] : [];
        const dependency = res.data.records.find((cr: any) => cr.name.toUpperCase() === "CN-TW DEPENDENCY ANALYSIS") ? [res.data.records.find((cr: any) => cr.name.toUpperCase() === "CN-TW DEPENDENCY ANALYSIS")] : [];
        const selectedReports = res.data.records.filter((cr: any) => cr.name.toUpperCase() !== "OPTICS CATALOG" && cr.name.toUpperCase() !== "CN-TW DEPENDENCY ANALYSIS");
        setCatalogReport(catalog);
        setDependencyReport(dependency);
        setOtherReport(selectedReports);
      })
  }, [searchParams]);

  const handlePcClick = (r: any) => {
    setDisplayReports(true);
    trackPageView({
      documentTitle: `Tools/Product_Catalog_${r.name}`,
    });

    navigate({
      pathname: `/domains/tools/productCatalog/${r.id}`,
    });
  };

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <Box display="flex" justifyContent="flex-end">
        <ButtonGroup sx={{ m: 2 }}>
          <Button
            variant={isList ? 'contained' : 'outlined'}
            onClick={() => {
              setIsList(true);
              setDisplayReports(false);
            }}
          >
            <AppsIcon></AppsIcon>
          </Button>
          <Button
            variant={!isList ? 'contained' : 'outlined'}
            onClick={() => {
              setIsList(false);
              setDisplayReports(false);
            }}
          >
            <ListIcon></ListIcon>
          </Button>
        </ButtonGroup>
      </Box>
      <Divider sx={{ marginBottom: 2 }} />

      {(!displayReports &&
        isList &&
        productCatalogs.length === 0 &&
        reports.length === 0)
        &&
        !hasRole(
          profile?.isCustomer ? 'TOOLS_DP-LLNB-LIVE' : 'ROLE_REPORT_USER',
        )
        && (
          <Typography align="center" variant="subtitle2">
            <ErrorAlert
              message="No tool is available"
              severity="info"
              onClose={() => {
                navigate('/');
              }}
            />
          </Typography>
        )}

      <Grid container direction="column">
        {!displayReports && isList && (
          <>
            {(catalogReport.length > 0 || productCatalogs.length > 0) &&
              <>
                <Typography variant="h6" component="div" style={{ fontWeight: 'bold' }} sx={{ ml: 2 }}>
                  Product Catalogs
                </Typography>
                <Grid container item spacing={2} direction="row" sx={{ mt: 3, ml: 2 }}>
                  <ToolsList
                    productCatalogs={productCatalogs}
                    handlePcClick={handlePcClick}
                  />
                  <PowerBiReports
                    isCatalogReport={true}
                    isDependencyReport={false}
                  />
                </Grid>
              </>
            }
            {otherReport.length > 0 &&
              <>
                <Typography variant="h6" component="div" style={{ fontWeight: 'bold' }} sx={{ ml: 2 }}>
                  Devices Suite
                </Typography>
                <Grid container item spacing={2} direction="row" sx={{ mt: 3, ml: 2 }}>
                  <PowerBiReports
                    isCatalogReport={false}
                    isDependencyReport={false}
                  />
                </Grid>

              </>
            }
            {dependencyReport.length > 0 &&
              <>
                <Typography variant="h6" component="div" style={{ fontWeight: 'bold' }} sx={{ ml: 2 }}>
                  Supplier Chain Insights
                </Typography>
                <Grid container item spacing={2} direction="row" sx={{ mt: 3, ml: 2 }}>
                  <PowerBiReports
                    isCatalogReport={false}
                    isDependencyReport={true}
                  />
                </Grid>
              </>
            }
          </>
        )}
      </Grid>


      {!displayReports && !isList && (
        <ToolsGrid
          allReports={reports}
          productCatalogs={productCatalogs}
          onReportClick={() => setDisplayReports(true)}
          handlePcClick={handlePcClick}
        ></ToolsGrid>
      )}
    </>
  );
}
