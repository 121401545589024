import {
  Breadcrumbs,
  Button,
  Divider,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import * as yup from 'yup';
import { Field, Form, Formik } from 'formik';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Layout from '../../Layout/Layout';
import { Save } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import apiClient from '../../../services/apiClient';
import Guard from '../../shared/Guard/Guard';
import { Role } from '../../../data';

function Config({
  index,
  configTitle,
  id,
}: {
  index: string;
  configTitle: string;
  id: number;
}) {
  const [isEditable, setIsEditable] = useState(false);
  const validationSchema = yup.object().shape({
    name: yup.string().required('The Bot Name is Required'),
    systemMessage: yup.string().required('The Bot System Message is Required'),
    temperature: yup
      .number()
      .required('The Bot temperature is Required')
      .min(0, 'The Bot temperature must be greater than 0')
      .max(1, 'The Bot temperature must be less than 1'),
  });

  const queryTypes = [
    {
      value: 'simple',
      label: 'Simple',
    },
    {
      value: 'semantic',
      label: 'Semantic',
    },
    {
      value: 'vector',
      label: 'Vector',
    },
    {
      value: 'vector_simple_hybrid',
      label: 'Vector Simple Hybrid',
    },
    {
      value: 'vector_semantic_hybrid',
      label: 'Vector Semantic Hybrid',
    },
  ];

  const [initialValues, setInitialValues] = useState<null | {
    name: string;
    systemMessage: string;
    index: string;
    semanticConfiguration: string;
    queryType: string;
    temperature: number;
    azureOpenAiModel: string;
  }>(null);

  useEffect(() => {
    apiClient.get(`/mbnBots/${id}`).then((res) => {
      const { data } = res;
      const {
        name,
        systemMessage,
        index,
        semanticConfiguration,
        queryType,
        temperature,
        azureOpenAiModel,
      } = data;

      setInitialValues({
        name,
        systemMessage,
        index,
        semanticConfiguration,
        queryType,
        temperature,
        azureOpenAiModel,
      });
    });
  }, [id]);

  return (
    <>
      {initialValues && (
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={(
            form,
            {
              resetForm,
              setSubmitting,
            }: { setSubmitting: any; resetForm: any },
          ) => {
            setSubmitting(true);
            setInitialValues(null);
            apiClient
              .put(`/mbnBots/${id}`, { bot: form })
              .then((res) => {
                const { data } = res;
                const {
                  name,
                  systemMessage,
                  index,
                  semanticConfiguration,
                  queryType,
                  temperature,
                  azureOpenAiModel,
                } = data;
                console.log(data);

                setInitialValues({
                  name,
                  systemMessage,
                  index,
                  semanticConfiguration,
                  queryType,
                  temperature,
                  azureOpenAiModel,
                });

                setIsEditable(false);
                resetForm();
              })
              .catch((error) => {
                console.log(error);
                setSubmitting(false);
              });
          }}
        >
          {({
            errors,
            touched,
            setTouched,
            isSubmitting,
            values,
            handleSubmit,
            submitForm,
          }) => (
            <Form>
              <Grid container spacing={2} p={1}>
                <Grid xs={12} item p={1}>
                  <Divider />
                </Grid>
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="flex-end"
                  alignItems="center"
                  sx={{ width: '100%' }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      submitForm();
                    }}
                    disabled={isSubmitting || !isEditable}
                  >
                    Update & Save <Save />
                  </Button>
                  <Button
                    color={isEditable ? 'primary' : 'secondary'}
                    variant="contained"
                    onClick={() => setIsEditable(!isEditable)}
                  >
                    {isEditable ? 'Cancel' : 'Edit'}
                  </Button>
                </Stack>

                <Grid xs={12} item>
                  <Typography variant="h6">{configTitle}</Typography>
                </Grid>
                <Grid xs={12} item>
                  <Field name="name">
                    {({ field }: { field: any }) => (
                      <TextField
                        {...field}
                        // value={field.value}
                        fullWidth
                        variant="filled"
                        label="Name"
                        error={Boolean(errors['name'] && touched['name'])}
                        helperText={Boolean(touched['name']) && errors['name']}
                        disabled={!isEditable}
                      ></TextField>
                    )}
                  </Field>
                </Grid>

             
                <Grid xs={6} item>
                  <Field name="systemMessage">
                    {({ field }: { field: any }) => (
                      <TextField
                        {...field}
                        fullWidth
                        variant="filled"
                        label="System Message"
                        error={Boolean(
                          errors['systemMessage'] && touched['systemMessage'],
                        )}
                        helperText={
                          Boolean(touched['systemMessage']) &&
                          errors['systemMessage']
                        }
                        disabled={!isEditable}
                      ></TextField>
                    )}
                  </Field>
                </Grid>
                <Grid xs={6} item>
                  <Field name="index">
                    {({ field }: { field: any }) => (
                      <TextField
                        {...field}
                        fullWidth
                        variant="filled"
                        label="Index"
                        error={Boolean(errors['index'] && touched['index'])}
                        helperText={
                          Boolean(touched['index']) && errors['index']
                        }
                        disabled={!isEditable}
                      ></TextField>
                    )}
                  </Field>
                </Grid>
               
                <Grid xs={6} item>
                  <Field name="semanticConfiguration">
                    {({ field }: { field: any }) => (
                      <TextField
                        {...field}
                        fullWidth
                        variant="filled"
                        label="Semantic Configuration"
                        disabled={
                          values.queryType === 'simple' ||
                          values.queryType === 'vector_simple_hybrid' ||
                          !isEditable
                        }
                        value={
                          values.queryType === 'simple' ||
                          values.queryType === 'vector_simple_hybrid'
                            ? ''
                            : field.value
                        }
                      ></TextField>
                    )}
                  </Field>
                </Grid>
                <Grid xs={6} item>
                  <Field name="azureOpenAiModel">
                    {({ field }: { field: any }) => (
                      <TextField
                        {...field}
                        fullWidth
                        variant="filled"
                        label="Azure OpenAi Model"
                        disabled={!isEditable}
                      ></TextField>
                    )}
                  </Field>
                </Grid>
                <Grid xs={6} item>
                  <Field name="queryType">
                    {({ field }: { field: any }) => (
                      <Select
                        {...field}
                        fullWidth
                        variant="filled"
                        label="Query Type"
                        disabled={!isEditable}
                      >
                        {queryTypes.map((type) => (
                          <MenuItem key={type.value} value={type.value}>
                            {type.label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </Field>
                </Grid>
                <Grid xs={6} item>
                  <Field name="temperature">
                    {({ field }: { field: any }) => (
                      <TextField
                        {...field}
                        fullWidth
                        variant="filled"
                        label="temperature"
                        error={Boolean(
                          errors['temperature'] && touched['temperature'],
                        )}
                        helperText={
                          Boolean(touched['temperature']) &&
                          errors['temperature']
                        }
                        disabled={!isEditable}
                      ></TextField>
                    )}
                  </Field>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
}

export default function MbnChatbotManager() {
  return (
    <Layout noBreadcrumbs>
      <Guard
        role={Role.MBN_CHATBOT_MANAGER}
        message="You do not have access to this page"
      >
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          sx={{ p: 2 }}
        >
          <Typography color="text.primary">Admin</Typography>
          <Typography color="text.secondary">Mbn Chatbot Management</Typography>
        </Breadcrumbs>

        <Config
          index="mbi-news-buyin"
          configTitle="BuyIn Configuration"
          id={1}
        />
        <Config index="mbi-news-dt" configTitle="DT Configuration" id={2} />
        <Config index="mbi-news-or" configTitle="OR Configuration" id={3} />
      </Guard>
    </Layout>
  );
}
